<mat-card>
  <mat-card-header>
    <h1 mat-card-title>Create Account / reset password</h1>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="form">
      <mat-form-field>
        <mat-label>Passwort</mat-label>
        <input formControlName="password" matInput type="password"/>
        <mat-error>Required field</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>repeat password</mat-label>
        <input formControlName="rePassword" matInput type="password">
        <mat-error>Password does not match</mat-error>
      </mat-form-field>

      <form-actions>
        <button (click)="updatePassword()" [disabled]="form.invalid || !form.dirty" color="primary" mat-raised-button>
          <mat-icon>exit_to_app</mat-icon>
          set Passwort
        </button>
      </form-actions>
    </form>
  </mat-card-content>

  <mat-card-actions>
    <a [routerLink]="['/login']">
      back to Login-Page
    </a>
  </mat-card-actions>
</mat-card>

